import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerRouting } from '@app/core/constants';

const routes: Routes = [
  {
    path: CustomerRouting.Root,
    loadChildren: () => import('@app/components/root/root.module').then((m) => m.RootModule)
  },
  {
    path: CustomerRouting.PagesRoot,
    loadChildren: () => import('@app/components/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: CustomerRouting.Page404,
    loadChildren: () => import('@app/components/errors/errors.module').then((m) => m.ErrorsModule)
  },
  {
    path: '**',
    redirectTo: CustomerRouting.Page404,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
