import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { StorageToken } from '@app/core/constants';
import { BehaviorSubject } from "rxjs";

@Injectable()

export class TokenService {
  public accessToken$: BehaviorSubject<any> = new BehaviorSubject(null);
  public refreshToken$: BehaviorSubject<any> = new BehaviorSubject(null);
  public expires$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private storage: StorageService
  ) {
    this.accessTokenStorage();
    this.refreshTokenStorage();
    this.expiresTokenStorage();
  }

  ngOnDestroy(): void {
  }

  /**
   * Setter for Access Token.
   * @param value
   */
  set accessToken(value: string) {
    this.storage.setStorage(
      {
        key: StorageToken.accessToken,
        value: value
      }
    ).then( e => this.accessToken$.next(value));
  }

  /**
   * Getter for Access Token.
   */
  get accessToken(): string {
    return this.accessToken$.getValue();
  }

  /**
   * Clear Access Token.
   */
  accessTokenClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.accessToken
      }
    ).then( e => this.accessToken$.next(null));
  }

  /**
   * Initial set access token from storage
   */
  accessTokenStorage(): void {
    this.accessToken$.next(
      this.storage.getStorage({ key: StorageToken.accessToken })
    );
  }



  /**
   * Setter for Refresh Token.
   * @param value
   */
  set refreshToken(value: string) {
    this.storage.setStorage(
      {
        key: StorageToken.refreshToken,
        value: value
      }
    ).then( e => this.refreshToken$.next(value));
  }

  /**
   * Getter for Refresh Token.
   */
  get refreshToken(): string {
    return this.refreshToken$.getValue();
  }

  /**
   * Clear Refresh Token.
   */
  refreshTokenClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.refreshToken
      }
    ).then( e => this.refreshToken$.next(null));
  }

  /**
   * Initial set refresh token from storage
   */
  refreshTokenStorage(): void {
    this.refreshToken$.next(
      this.storage.getStorage({ key: StorageToken.refreshToken })
    );
  }


  /**
   * Setter for Expires.
   * @param value
   */
  set expires(value: string) {
    this.storage.setStorage(
      {
        key: StorageToken.expires,
        value: value
      }
    ).then( e => this.expires$.next(value));
  }

  /**
   * Getter for Expires.
   */
  get expires(): string {
    return this.expires$.getValue();
  }

  /**
   * Clear expires Token.
   */
  expiresClear(): void {
    this.storage.removeStorage(
      {
        key: StorageToken.expires
      }
    ).then( e => this.expires$.next(null));
  }

  /**
   * Initial set expires token from storage
   */
  expiresTokenStorage(): void {
    this.expires$.next(
      this.storage.getStorage({ key: StorageToken.expires })
    );
  }
}
