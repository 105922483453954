import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Directives.
 */
import { DevicesDirective } from '../directives';



/**
 * Services.
 */
import {
  DomService,
  DropdownService,
  PopupService,
  SEOService,
  StorageService,
  TokenService
} from '@app/core/services';

/**
 * Interceptors
 */
import { HttpRequestInterceptor } from '@app/core/interceptor';

/**
 * Vendors
 */
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    DevicesDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    CookieService,
    DomService,
    DropdownService,
    PopupService,
    SEOService,
    StorageService,
    TokenService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
