import { Routes } from '@angular/router';
import { CustomerRouting } from '@app/core/constants';
const ɵ0 = () => import("./../components/root/root.module.ngfactory").then((m) => m.RootModuleNgFactory), ɵ1 = () => import("./../components/pages/pages.module.ngfactory").then((m) => m.PagesModuleNgFactory), ɵ2 = () => import("./../components/errors/errors.module.ngfactory").then((m) => m.ErrorsModuleNgFactory);
const routes = [
    {
        path: CustomerRouting.Root,
        loadChildren: ɵ0
    },
    {
        path: CustomerRouting.PagesRoot,
        loadChildren: ɵ1
    },
    {
        path: CustomerRouting.Page404,
        loadChildren: ɵ2
    },
    {
        path: '**',
        redirectTo: CustomerRouting.Page404,
        pathMatch: 'full'
    }
];
export class RoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
