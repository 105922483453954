import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { map, filter, mergeMap} from 'rxjs/operators';
import { untilDestroyed } from "@app/utils/until-destroyed";
import { PageInterface } from "./seo.model";
import { CustomerSEO } from '@app/core/constants';

@Injectable()

export class SEOService implements OnDestroy {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private metaTag: Meta
  ) {}

  ngOnDestroy(): void {
  }

  public init(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(_ => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      untilDestroyed(this)

    ).subscribe((event) => {
      // tslint:disable-next-line:no-shadowed-variable
      const Title = event.title ? CustomerSEO.baseTitle + ' | ' + event.title : CustomerSEO.baseTitle;
      const Description = event.description ? event.description : CustomerSEO.baseDescription;
      const Keywords = event.keywords ? event.keywords : CustomerSEO.baseKeywords;

      this.setPageSchema({
        title: Title,
        description: Description,
        keywords: Keywords,
        image: '',
        url: ''
      });
    });
  }

  /**
   * Set of custom meta schema.
   * @param pageData: {
   *   title,
   *   description,
   *   keywords,
   *   image,
   *   url
   * }
   */
  public setPageSchema(pageData: PageInterface): void {
    this.title.setTitle(pageData.title);
    this.metaTag.updateTag(
      {
        name: 'description',
        content: pageData.description
      }
    );
    this.metaTag.updateTag(
      {
        name: 'keywords',
        content: pageData.keywords
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:description',
        content: pageData.description
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:image',
        content: pageData.image
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:image:secure_url',
        content: pageData.image
      }
    );
    this.metaTag.updateTag(
      {
        name: 'og:url',
        content: pageData.url || location.href
      }
    );
  }
}
