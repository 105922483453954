/**
 * Available routing URL's of customer.
 */
export const CustomerRouting = {
  /**
   * Root path.
   */
  Root: '',

  /**
   * Pages path.
   */
  PagesRoot: 'pages',
  About: 'about',
  Products: 'products',
  Contacts: 'contacts',
  OrderForm: 'order-form',

  /**
   * Errors
   */
  Page404               : '404'
};
