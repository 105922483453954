import { Injectable } from '@angular/core';
import { HandlerEvent, ClassLists } from '@app/core/constants';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()

export class DropdownService {
  /**
   * Prefix for DOM-selectors.
   * @string
   */
  private Prefix = 'ui-dropdown';


  /**
   * Lists of DOM-selectors.
   */
  private SelectorUI = {
    link: this.Prefix + '-link',
    container: this.Prefix + '-container',
    content: this.Prefix + '-content',
    close: this.Prefix + '-close',
  };


  /**
   * Lists of DOM-animation.
   */
  private AnimationUI = {
    sideIn: 'ui-animate-side-in-right',
    sideOut: 'ui-animate-side-out-right',
  };


  private Settings = {
    animation: true
  };


  /**
   * Type of click.
   */
  private ClickEvent: string = HandlerEvent.desktop.click;

  constructor(
    private router: Router
  ) {
    this.deviceHandler();
  }

  build() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.dropDownHideAll();
      }
    });
    this.dropDownContainer();
  }

  rebuild() {
    setTimeout(() => {
      this.dropDownContainer();
    }, 100);
  }

  configure(settings?: any) {
    if (settings && JSON.stringify(settings) !== '{}') {
      for (const key in settings) {
        if (this.Settings.hasOwnProperty(key)) {
          this.Settings[key] = settings[key];
        }
      }
    }
  }

  dropDownContainer() {
    setTimeout(() => {
      const DDContainer = document.querySelectorAll('.' + this.SelectorUI.container);

      for (let i = 0; i < DDContainer.length; i++) {
        this.dropDownLinks(DDContainer[i]);
      }
    }, 0);
  }

  dropDownLinks(container: Element) {
    const DDLinks = container.getElementsByClassName(this.SelectorUI.link);

    for (let i = 0; i < DDLinks.length; i++) {
      DDLinks[i].addEventListener(this.ClickEvent, e => {
        e.preventDefault();

        const $element = DDLinks[i];

        if ($element instanceof Element) {
          this.dropDownClickHandler(container, $element);
          this.dropDownClickOutside();
        }
      }, true);
    }
  }

  dropDownClickHandler(container: Element, link: Element) {
    const DDContent = container.getElementsByClassName(this.SelectorUI.content);

    if (!DDContent.length) {
      return;
    }

    this.dropDownResetActiveLink();
    this.dropDownHideContent(DDContent[0] as HTMLElement);
    this.dropDownViewContent(DDContent[0] as HTMLElement, link);
  }

  dropDownClickOutside() {
    const removeClickListener = () => {
      document.removeEventListener(this.ClickEvent, outsideClickListener);
    };

    const outsideClickListener = e => {
      if (e.target.closest(`.${this.SelectorUI.link}`) === null) {
        this.dropDownResetActiveLink();
        this.dropDownHideAll();

        /**
         * Remove handler.
         */
        removeClickListener();
      }
    };

    document.addEventListener(this.ClickEvent, outsideClickListener);
  }

  dropDownViewContent(content: HTMLElement, link: Element) {
    if (content.hasAttribute('class') && content.style !== null) {
      link.classList.add(ClassLists.active);

      content.style.display = 'block';

      if (this.Settings.animation) {
        content.classList.add(this.AnimationUI.sideIn);
      }
    }
  }

  dropDownHideContent(content?: HTMLElement) {
    const DDContent = document.getElementsByClassName(this.SelectorUI.content) as HTMLCollection;

    for (const k of Object.keys(DDContent)) {
      if (!content.contains(DDContent[k])) {
        this.dropDownResetView(DDContent[k] as HTMLElement);
      }
    }
  }

  dropDownHideAll() {
    const DDContent = document.getElementsByClassName(this.SelectorUI.content) as HTMLCollection;

    for (const k of Object.keys(DDContent)) {
      this.dropDownResetView(DDContent[k] as HTMLElement);
    }

    this.dropDownResetActiveLink();
  }

  dropDownResetActiveLink() {
    const DDLink = document.getElementsByClassName(this.SelectorUI.link) as HTMLCollection;

    for (const l of Object.keys(DDLink)) {
      DDLink[l].classList.remove(ClassLists.active);
    }
  }

  dropDownResetView(element: HTMLElement) {
    element.classList.add(this.AnimationUI.sideOut);

    setTimeout(() => {
      this.dropDownResetAnimation(element);
    }, 500);
  }

  dropDownResetAnimation(element: HTMLElement) {
    for (const key of Object.keys(this.AnimationUI)) {
      element.style.display = '';
      element.classList.remove(this.AnimationUI[key]);
    }
  }

  deviceHandler() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.ClickEvent = HandlerEvent.mobile.click;
    }
  }
}
