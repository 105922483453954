import { environment } from '../environments/environment';

/**
 * Prefix of current application.
 */
export const AppPrefix = 'MR';

/**
 * Path of static sources/
 */
export const AppStatic = './assets/';

/**
 * Path of static sources/
 */
export const AppHeading = {
  'ru-RU': '',
  'en-US': ''
};

export const AppApiURL = environment.apiUrl;
