import * as tslib_1 from "tslib";
import { AppPrefix } from '@app/app.settings';
export class StorageService {
    constructor(cookieService, local, session) {
        this.cookieService = cookieService;
        this.local = local;
        this.session = session;
        this.$window = window;
        this.$storage = 'local';
    }
    /**
     * Writes down values in Cookies.
     * @params{
     *   name: string,
     *   value: any
     *   expires: number
     *   path: string
     * }
     */
    setCookie(options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            options.name = this.prefixValue(options.name);
            yield this.cookieService.set(options.name, this.convertValue(options.value), options.expires || 30, options.path || '/');
        });
    }
    /**
     * Get value from cookies by key.
     * If empty value, then will return empty string.
     * @params{
     *   key: string
     * }
     */
    getCookie(key) {
        return this.cookieService.get(this.prefixValue(key)) || '';
    }
    /**
     * Checking value of cookie.
     * @params{
     *   key: string
     * }
     */
    checkCookie(key) {
        return this.cookieService.check(this.prefixValue(key));
    }
    /**
     * Remove all cookies.
     * Return Promise.
     * @params{
     *   key: string
     * }
     */
    deleteCookie(options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            options.name = this.prefixValue(options.name);
            yield this.cookieService.delete(options.name, options.path || '/', options.domain || '');
        });
    }
    /**
     * Remove all cookies.
     * Return Promise.
     * @params{
     *   key: string
     * }
     */
    deleteAllCookie(path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cookieService.deleteAll(path || '/');
        });
    }
    /**
     * Writes down values in Local Storage.
     * Return Promise.
     * @params{
     *   key: string,
     *   value: any,
     *   storage: string,
     * }
     */
    setStorage(options, storage = this.$storage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (storage === 'local') {
                this.local.setItem(this.prefixValue(options.key), this.convertValue(options.value));
            }
            if (storage === 'session') {
                this.session.setItem(this.prefixValue(options.key), this.convertValue(options.value));
            }
            // this.$window[storage + 'Storage'].setItem(this.prefixValue(options.key), this.convertValue(options.value));
        });
    }
    /**
     * Get value from local storage by key.
     * If empty value, then will return empty object as string
     * @params{
     *   key: string,
     *   storage: string,
     * }
     */
    getStorage(options, storage = this.$storage) {
        if (storage === 'local') {
            return this.local.getItem(this.prefixValue(options.key)) || null;
        }
        if (storage === 'session') {
            return this.session.getItem(this.prefixValue(options.key)) || null;
        }
        // const StorageValue = this.$window[storage + 'Storage'].getItem(this.prefixValue(options.key)) || null;
        //
        // return StorageValue;
    }
    /**
     * Get value from local storage by key.
     * If empty value, then will return empty object as string
     * @params{
     *   key: string,
     *   storage: string,
     * }
     */
    removeStorage(options, storage = this.$storage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (storage === 'local') {
                return this.local.removeItem(this.prefixValue(options.key)) || {};
            }
            if (storage === 'session') {
                return this.session.removeItem(this.prefixValue(options.key)) || {};
            }
            // return this.$window[storage + 'Storage'].removeItem(this.prefixValue(options.key)) || {};
        });
    }
    /**
     * Clear all values from session storage.
     * @params{
     *   storage: string
     * }
     */
    clearStorage(storage = this.$storage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (storage === 'local') {
                return this.local.clear();
            }
            if (storage === 'session') {
                return this.session.clear();
            }
            // return this.$window[storage + 'Storage'].clear();
        });
    }
    /**
     * Converting any values in string.
     */
    convertValue(value) {
        return typeof value !== 'string' ? JSON.stringify(value) : value;
    }
    /**
     * Add prefix to value.
     * @param value
     */
    prefixValue(value) {
        return !!AppPrefix && typeof AppPrefix === 'string' && AppPrefix.length ? AppPrefix.toLowerCase() + '-' + value : value;
    }
}
