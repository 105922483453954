<div class="app__layout">
  <div class="parallax__wrapper">
    <div class="parallax__needles">
      <!--<span class="parallax__needles-top animated faster" ngClass="{{onNeedlesRootAnimation ? 'bounceInDown delay-0_5s' : 'fadeIn delay-0_5s'}}"></span>
      <span class="parallax__needles-bottom animated faster" ngClass="{{onNeedlesRootAnimation ? 'bounceInUp delay-0_5s' : 'fadeIn delay-0_5s'}}"></span>-->
      <span class="parallax__needles-top animated faster bounceInDown" ngClass="{{onNeedlesRootAnimation ? 'delay-0_5s' : 'delay-1-5s'}}"></span>
      <span class="parallax__needles-bottom animated faster bounceInUp" ngClass="{{onNeedlesRootAnimation ? 'delay-0_5s' : 'delay-1-5s'}}"></span>
    </div>
    <img class="parallax__logo" src="/assets/images/template/bg/parallax/bg-brand.png">
  </div>
  <div class="app__outlet">
    <router-outlet></router-outlet>
  </div>
</div>
