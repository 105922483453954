/**
 * Available handlers for EventListener.
 */
export const HandlerEvent = {
  desktop: {
    click: 'click',
    mousemove: 'mousemove',
    mousedown: 'mousedown',
    mouseup: 'mouseup',
    keypress: 'keypress'
  },
  mobile: {
    click: 'touchend',
    touchmove: 'touchmove',
  }
};
