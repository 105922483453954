import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { SEOService } from '@app/core/services';

declare var $;
declare var TweenMax;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private seo: SEOService
  ) {}

  ngOnInit(): void {
    this.seo.init();
    this.onParallaxEffect();
  }

  onParallaxEffect(): void {
    const parallaxIt = (e, target, movement) => {
      const $this = $('.parallax__wrapper');
      const relX = e.pageX - $this.offset().left;
      const relY = e.pageY - $this.offset().top;

      TweenMax.to(target, 1, {
        x: (relX - $this.width() / 2) / $this.width() * movement,
        y: (relY - $this.height() / 2) / $this.height() * movement
      });
    };

    $('.app__layout').on('mousemove', (e) => {
      parallaxIt(e, '.parallax__needles', -30);
      parallaxIt(e, '.parallax__logo', 30);
    });
  }

  get onNeedlesRootAnimation(): boolean {
    return window.location.pathname === '/';
  }
}
